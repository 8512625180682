import React from "react";

export default function TermsAndConditions(){
    const [data,setData] = React.useState([]);

    return (
        <section id = "terms-and-conditions">
            <h1>Skolasti - Terms & conditions</h1>
            <h2>Effective Date: 20/07/2024</h2>
            <h2>1. Introduction</h2>
            <p>Welcome to Skolasti. These Terms and Conditions govern your access to and use of our website <a href="https://app.skolasti.com/inovartech" target="blank">Skolasti</a> and its associated services. By accessing or using our Services, you agree to be bound by these Terms.</p>
            <h2>2. User Accounts</h2>
            <ul>
                <li><b>Eligibility:</b>To use our Services, you must be at least 18 years old or have the consent of a parent or guardian.</li>
                <li><b>Account Creation:</b>You are responsible for providing accurate and complete information when creating an account. You must keep your account information up-to-date and secure.</li>
                <li><b>Account Security:</b>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
            </ul>
            <h2>3. Use of Services</h2>
            <ul>
                <li><b>License:</b>We grant you a limited, non-exclusive, non-transferable license to access and use our Services for personal, educational purposes only.</li>
                <li>
                    <b>Prohibited Activities:</b>
                    <ul>
                        <li>Violate any applicable laws or regulations.</li>
                        <li>Use our Services for any unlawful or unauthorized purposes.</li>
                        <li>Disrupt or interfere with the operation of our Services.</li>
                        <li>Transmit or upload any content that is harmful, defamatory, or infringing on intellectual property rights.</li>
                    </ul>
                </li>
            </ul>
            <h2>4. Content</h2>
            <ul>
                <li><b>User Content:</b>You retain ownership of any content you upload or submit to our Services. By doing so, you grant us a worldwide, royalty-free, and non-exclusive license to use, modify, and display your content as necessary for the operation of our Services.</li>
                <li><b>Intellectual Property:</b>All content and materials provided on our Services are the property of Skolasti or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
            </ul>
            <h2>5. Fees and Payment</h2>
            <ul>
                <li><b>Subscription:</b>Access to certain features of our Services may require a subscription or payment. The details of pricing and payment terms will be provided at the time of purchase.</li>
                <li><b>Refunds:</b>Refunds are subject to our refund policy, which will be detailed on our website or in correspondence with you.</li>
            </ul>
            <h2>6. Termination</h2>
            <ul>
                <li><b>Termination by Us:</b>We may suspend or terminate your access to our Services at any time, with or without cause, if we believe you have violated these Terms.</li>
                <li><b>Termination by You:</b>You may terminate your account at any time by contacting us or following the instructions on our website.</li>
            </ul>
            <h2>7. Disclaimers and Limitations of Liability</h2>
            <ul>
                <li><b>No Warranty:</b>Our Services are provided "as is" without any warranties of any kind, either express or implied.</li>
                <li><b>Limitation of Liability:</b>To the fullest extent permitted by law, Skolasti shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Services.</li>
            </ul>
            <h2>8. Changes to Terms</h2>
            <p>We may update these Terms from time to time. We will notify you of any significant changes, and your continued use of our Services after such changes constitutes your acceptance of the revised Terms.</p>
            <h2>9. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising from or related to these Terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>
            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms, please contact us at: <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a></p>
        </section>

    )
}