import React from "react";
export default function PrivacyAndPolicy() {
    return (
        <section className="privacy-policy-section">
            <div>
                <h1>Skolasti ‐ Privacy Policy</h1>
                <h2>Effective Date: 20/07/2024</h2>
                <p>We recognize that visitors to our Skolasti may be concerned about what happens to information they provide when they make use of the system.</p>
                <p>We also recognize that education and training establishments have a duty of care to protect the privacy of information provided by their students and employees when they make use of the Skolasti.</p>
                <p>This privacy policy outlines the obligations and requirements of InovarTech and of the education and training establishments that make use of the Skolasti.</p>
                <p>This Privacy Policy covers our policies and practices regarding collection, use and disclosure of personal information we receive about you when you use our products or services or websites, or otherwise interact with us (for example, by attending our webinars or events), unless a different policy is displayed. This Privacy Policy also explains your rights and the choices available to you regarding our use of your personal information and how you can access and update this information.
                    InovarTech, we and us refers to InovarTech and our products, websites and services are together referred to as "Services" in this policy.
                    We carry out all processing operations in compliance with the US and INDIA Data protection Act
                    We reserve the right to amend all or part of our Privacy Policy from time to time. The version published on the Sites is the version currently in force.
                </p>
                <div>
                    <details open>
                        <summary>What information we collect about you:</summary>
                        <p>We collect the following personal information from you:</p>
                        <ul>
                            <li>Contact Information such as name, email address, phone number, postal address, billing information, information you input in open text fields in help/contact us/feedback forms.</li>
                            <li>Information about your business such as company name, company size, industry, and company contact information.</li>
                            <li>Your profile information like profile photo, display name, job title and other details to be displayed in our Services.</li>
                            <li>Content you upload to the Services such as files and internet links. Although InovarTech owns the code, databases and all rights to the Services, Customers retain ownership, control and all rights to their records and data which are their property.</li>
                            <li>Content usage information such as what content is already seen, time spent on content, content interactions and other details related to usage.</li>
                            <li>Information about how you interact with our Services including the features you use; the links you click on; the type, size and filenames of attachments you upload to the Services; frequently used search terms; and how you interact with others on the Services.</li>
                            <li>Device information such as your connection type, device operating system, browser type, IP address, URLs of referring/exit pages and crash data.</li>
                            <li>Information submitted to our customer support, such as contact information, problem summary, and any other documentation, screenshots or information that would be helpful in resolving an issue submitted.</li>
                            <li>We do not store your credit card information in our systems. All credit card transactions are processed using secure encryption - the same level of encryption used by leading banks. Card information is transmitted, stored and processed securely at gateways on a PCI-compliant network.</li>
                        </ul>
                    </details>
                    <details open>
                        <summary>How we use information we collect:</summary>
                        <p>We use this information to:</p>
                        <ul>
                            <li>Schedule your demo requests.</li>
                            <li>Send you purchase confirmation.</li>
                            <li>Administer your account.</li>
                            <li>Handle customer service requests.</li>
                            <li>Send you marketing communications.</li>
                            <li>For research and development and improvement of the Services.</li>
                            <li>To communicate with you about the Services.</li>
                            <li>For customer support to resolve technical issues and repair and improve the Services.</li>
                            <li>To monitor suspicious or fraudulent activity, verify accounts and to identify violations of Service policies</li>
                            <li>To protect our legal rights and legitimate business interests</li>
                        </ul>
                    </details>
                    <details open>
                        <summary>Sharing of personal information:</summary>
                        <p>We will share your personal information with third parties only in the ways that are described in this privacy statement.</p>
                    </details>
                    <details open>
                        <summary>Service Providers:</summary>
                        <p>We may provide your personal information to companies that provide services to help us with our business activities such as processing your payments. These companies are authorized to use your personal information only as necessary to provide these services to us.</p>
                    </details>
                    <details open>
                        <summary>Legal Disclaimer:</summary>
                        <p>We may disclose personal information in the good faith that we are required to do so by law, including but not limited to disclosure to law enforcement or other government officials in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose you or us to legal liability.</p>
                    </details>
                    <details open>
                        <summary>Opt-out preferences:</summary>
                        <p>If you no longer want to receive marketing-related emails from us, you may opt out of receiving such emails by clicking the “unsubscribe” link at the bottom of any marketing email you receive from us.</p>
                        <p>If you are having difficulty unsubscribing from our marketing emails using the above methods, please contact us directly at the email <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a>.</p>
                    </details>
                    <details open>
                        <summary>Exercising your rights:</summary>
                        <p>You have the right to rectify inaccurate or incomplete personal information, request deletion of your personal information, or request that we no longer use it. You may request information on personal data collected, used, disclosed or processed by InovarTech, as well as a request for rectification, or erasure, or object to our processing of your personal data. Upon request, InovarTech will provide you with information about whether we hold any of your personal information. Furthermore, you may also be able to exercise the following rights to restrict processing, data portability, and lodge a complaint with a data protection authority.</p>
                        <p>In particular, you have the right to object and withdraw your consent, in whole or in part, to the collection, use, disclosure or processing of your personal data for purposes of dispatch of advertising material, direct selling or for the fulfillment of marketing surveys or email.</p>
                        <p>In order to exercise your rights above and/or submit inquiries or complaints with regard to our processing of your personal data, you may send a request to our email address <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a>. We will respond to your request within 30 days.</p>
                    </details>
                    <details open>
                        <summary>Security:</summary>
                        <p>The security of your personal information is important to us.</p>
                        <p>We follow generally accepted security standards to protect your personal data against accidental or unlawful loss, alteration, or unauthorized access.</p>
                        <p>By using the Services or providing personal information to us, you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. You may have a legal right to receive written notice of a data privacy or security breach depending on where you live. If you have any reason to believe that your interactions with the Services are no longer secure, please notify us immediately at <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a>.</p>
                        <p>If you have any questions about the security of your personal information, you can contact us at <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a>.</p>
                    </details>
                    <details open>
                        <summary>Disclosures specific to the InovarTech Skolasti platform/service:</summary>
                        <p>An individual who seeks access, or who wants to correct, amend, or delete inaccurate data should direct his query to the InovarTech’s Client (the data controller).</p>
                        <p>We will retain personal data we process on behalf of our Clients for as long as needed to provide services to our Client. InovarTech will retain this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                    </details>
                    <details open>
                        <summary>Contact us:</summary>
                        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@inovar-tech.com"> hello@inovar-tech.com</a>.</p>
                    </details>

                    <h1 className="mt-5">By using the Skolasti users agree to the terms and conditions outlined in this policy.</h1>
                    <details open>
                        <summary>1.Services and Personal Data</summary>
                        <ol>
                            <li>1.1. The Skolasti is a cloud‐based learning environment provided by InovarTech to its educational and training establishment customers</li>
                            <li>1.2. InovarTech provide customers with a set of login credentials for pre‐generated system accounts.</li>
                            <li>1.3. InovarTech licenses the use of learning materials to customers based on the products they have purchased.</li>
                            <li>1.4. Customers can make use of the Skolasti to provide educational material to their employees and students.</li>
                            <li>1.5. Customers can enable their employees and students to access the Skolasti individually by creating user accounts. When creating a user account an initial set of data is required which includes a username, first name, last name,<br />mail address and password. Customers can choose whether to use real names or aliases for this data. Customers can enable employees and students to self‐register on the Skolasti.</li>
                            <li>1.6. Customers can assign learning content to user accounts to meet their particular requirements. This can be achieved by allocation of user accounts to groups. As this happens employee and student group membership and assigned work is stored within the Skolasti.</li>
                            <li>1.7. Customers are required to ensure that system account credentials are kept secure.</li>
                            <li>1.8. As students work through the learning content, their results are tracked and stored within the Skolasti.</li>
                            <li>1.9. Employees have the ability to generate reports based on student data.</li>
                            <li>1.10. Students can print their performance data reports for personal retention and control.</li>
                            <li>1.11. Students can edit their own password.</li>
                            <li>1.12. Students can request that their teacher or administrator edit their user details including first name, last name, email address and password.</li>
                            <li>1.13. Employees can edit or delete student user personal details.</li>
                            <li>1.14. Employees can delete student result data.</li>
                            <li>1.15. Customers are required to ensure that any Personal Data that is extracted from the Skolasti by its employees is safeguarded.</li>
                            <li>1.16. InovarTech operates an archiving system to retain student and employee data for a period of no more than 7 years.</li>
                            <li>1.17. InovarTech retains the right to share aggregated de‐identified student data for the development, promotion and improvement of its Services.</li>
                            <li>1.18. Employee and student data stored within the Skolasti is and will remain the property of the customer.</li>
                            <li>1.19. Under no circumstances will InovarTech act as or become the Data Controller of the Personal Data. The customer is and will stay the sole Data Controller of the Personal Data.</li>
                            <li>1.20. Access to student data requires a site code along with either; a student username and password, or an employee username and password.</li>
                            <li>1.21. Individuals can request a copy of all data relating to them stored within the Skolasti. This request should be made to the individual’s Skolasti site administrator. The Skolasti provides the site administrator with the ability to generate data for an individual in a tab‐separated file that can be opened in popular spreadsheet software and text editors.</li>
                            <li>1.22. Individuals can request that data held about them, within the Skolasti, is rectified. This request should be made to the individual’s Skolasti site administrator. The Skolasti provides the site administrator with the ability to rectify data for an individual.</li>
                            <li>1.23. Individuals can request that all data relating to them, within the Skolasti, is permanently deleted. This request should be made to the Skolasti site administrator. The Skolasti provides the site administrator the ability to permanently delete all data for an individual.</li>
                            <li>1.24. Individuals that have an Skolasti account have the right to complain to the Information Commissioner’s Office if they believe there is a problem with the way their data is being handled.</li>
                            <li>1.25. The Skolasti website makes use of TLS (Transport Layer Security) to guarantee the integrity of the web pages and to ensure customer data is transported securely through an encrypted (SHA‐256) point‐to‐point tunnel between browser and server.</li>
                        </ol>
                    </details>
                </div>
            </div>
        </section>
    )
}
