import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import Cookies from "js-cookie";
import ErrorTab from "../Common/ErrorTab";
import Spinner from "../Common/Spinner";
import VideoUploadedDate from "../Common/VideoUploadedDate";

export const TutorialList = (props) => {
  
  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((tutorial, idx) => {
          const detailId = tutorial.Id;

          return (
            <li className="card-space" key={detailId}>
              <Link to={"/videos/" + detailId} id={"listcard" + idx}>
                <div className=" card-img">
                  <img 
                  src={tutorial.ThumbnailUrl} 
                  alt="lms-logo" />
                </div>
                <div className=" card-info lms-flex-between">
                  <div>
                    <p>{tutorial.CategoryName}</p>
                  </div>
                  <div className="card-date">
                    <VideoUploadedDate createdDate={tutorial.CreatedDate} />
                  </div>
                </div>
                <div className="card-heading">{tutorial.Title}</div>
                <div className="card-para">{tutorial.Description}</div>
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  }
  else{
    return <ErrorTab class={"no-result"}/>
  }
};

export default TutorialList;
