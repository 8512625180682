import React, { Component } from "react";
import { connect } from "react-redux";
import PlaceholderLoader from "../../components/Common/PlaceholderLoader";
import { bindActionCreators } from "redux";
import { fetchSharedWithMeVideos, fetchSharedWithmeVideosLoadMore } from "../../store/actions/tutorials";
import { fetchSharedWithMeCourses, fetchSharedWithMeCourseLoadMore } from "../../store/actions/courses";
import BrowseHeader from "./BrowseHeader";
import BrowseBody from "./BrowseBody";
import API from "../../utils/API";
import LoadingScreen from "../../components/Common/LoadingScreen";
import { BlueBarTranslated } from "../../components/Common/BlueBar";

export class BrowseSharedWithYou extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      videoHasMore: false,
      courseHasMore: true,
      filterVideoData: {
          Count: 0,
          Videos: []
        },
      filterCourseData: {
          Count: 0,
          Playlist: []
        },
      filterField: "",
      sortField: "",
      search: "",
    }
  }

  async componentDidMount() {
    const { fetchSharedWithMeCourses, fetchSharedWithMeVideos } = this.props;
    const videoPayload = { PageNumber: this.props.sharedWithMeVideoPageNumber - 1 };
    const coursePayload = { PageNumber: this.props.sharedWithMeCoursePageNumber - 1 };
    fetchSharedWithMeCourses(coursePayload);
    fetchSharedWithMeVideos(videoPayload);
  }

  filterData = (evt) => {
    this.setState({ search: evt.trim() })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedWithMeCourses({
        filterField: 'Title',
        filterText: evt
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedWithMeVideos({
        filterField: 'Title',
        filterText: evt
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  changeSort = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      sortField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedWithMeCourses({
        sortField: 'CreatedDate',
        sortType: evt,
        FilterField: 'CategoryName',
        FilterText: this.state.filterField,
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedWithMeVideos({
        sortField: 'CreatedDate',
        sortType: evt,
        FilterField: 'CategoryName',
        FilterText: this.state.filterField,
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  change = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      filterField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedWithMeCourses({
        filterField: 'CategoryName',
        filterText: evt,
        SortField: 'CreatedDate',
        SortType: this.state.sortField,
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedWithMeVideos({
        filterField: 'CategoryName',
        filterText: evt,
        SortField: 'CreatedDate',
        SortType: this.state.sortField,
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  handleBrowseVideoScroll = (event) => {
    const { fetchSharedWithmeVideosLoadMore, videoLazyloader, sharedWithMeVideoPageNumber } = this.props;
    const payload = {
      PageNumber: sharedWithMeVideoPageNumber
    };
    if (videoLazyloader === false) {
      this.setState({
        videoHasMore: false
      })
    }
    setTimeout(() => {
      fetchSharedWithmeVideosLoadMore(payload);
    }, 1000);

  }

  handleBrowseCourseScroll = (event) => {
    const { fetchSharedWithMeCourseLoadMore, courseLazyLoader, sharedWithMeCoursePageNumber } = this.props;
    const payload = {
      PageNumber: sharedWithMeCoursePageNumber
    };
    if (courseLazyLoader === false) {
      this.setState({
        courseHasMore: false
      })
    }
    setTimeout(() => {
      fetchSharedWithMeCourseLoadMore(payload);
    }, 1000);
  }

  render() {
    const { loaded, sharedWithMeVideos, sharedWithMeCourses, videoLazyloader, courseLazyLoader } = this.props;
    return loaded ? (
      <div>
        <BlueBarTranslated props = {this.props}/>
        <BrowseHeader props={this.props} browseType={"SharedWithMe"} filterData={(evt) => this.filterData(evt)} changeSort={(evt) => this.changeSort(evt)} change={(evt) => this.change(evt)}></BrowseHeader>
        <BrowseBody
          props={this.props}
          tutorials={sharedWithMeVideos}
          courses={sharedWithMeCourses}
          loaded={loaded}
          searchValue={this.state.search}
          filterDataVideo={this.state.filterVideoData}
          filterCourseData={this.state.filterCourseData}
          handleBrowseVideoScroll={this.handleBrowseVideoScroll}
          handleBrowseCourseScroll={this.handleBrowseCourseScroll}
          videoLazyloader={videoLazyloader}
          courseLazyLoader={courseLazyLoader}
          videoHasMore = {this.state.videoHasMore}
          courseHasMore = {this.state.courseHasMore}
          type="sharewithyou"
        ></BrowseBody>
      </div>
    ) : (
      <LoadingScreen></LoadingScreen>
    );
  }
}

const mapStateToProps = (state, props) => ({
  loaded: state.tutorials.loaded,
  videoLazyloader: state.tutorials.sharedWithMeVideoLazyLoader,
  courseLazyLoader: state.courses.sharedWithmeCourseLazyLoader,
  profile: state.user,
  sharedWithMeCourses: state.courses.sharedwithmecouses,
  sharedWithMeVideos: state.tutorials.sharedwithmevideos,
  sharedWithMeVideoPageNumber: state.tutorials.sharedWithMeVideoPageNumber,
  sharedWithMeCoursePageNumber: state.courses.sharedWithMeCoursePageNumber
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSharedWithMeCourses: fetchSharedWithMeCourses,
      fetchSharedWithMeVideos: fetchSharedWithMeVideos,
      fetchSharedWithmeVideosLoadMore: fetchSharedWithmeVideosLoadMore,
      fetchSharedWithMeCourseLoadMore: fetchSharedWithMeCourseLoadMore
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseSharedWithYou);
