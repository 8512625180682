
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { blueBarAction } from "../../store/actions/BlueBarAction";
import { Doughnut } from "react-chartjs-2";
class BlueBar extends Component {
  state = {
    displayBlueBar: true,
    badgeTitle:"No Badges",
    scorePoints: 0,
  };
  handleBlueBar = () => {
    const { handleBlueBar } = this.props
    handleBlueBar(false)
  }
  CountTotalCourse() {
    return isNaN(this.ProgressToTotalCoursePercentage()) ? 50 : (this.props.ShareCourseTotal + this.props.SavedCourseTotal)
  }
  CountProgressCourse() {
    return isNaN(this.ProgressToTotalCoursePercentage()) ? 20 : (this.props.ShareCourseProgress + this.props.SavedCourseProgress)
  }
  ProgressToTotalCoursePercentage() {
    const { ShareCourseTotal, ShareCourseProgress, SavedCourseTotal, SavedCourseProgress } = this.props
    var percentage = ((ShareCourseProgress + SavedCourseProgress) / (ShareCourseTotal + SavedCourseTotal)) * 100
    return isNaN(percentage) ? 0 : Math.floor(percentage)
  }
  completedToTotalCoursePercentage() {
    const { ShareCourseTotal, ShareCourseCompleted, SavedCourseTotal, SaveCourseCompleted } = this.props
    var percentage = ((ShareCourseCompleted + SaveCourseCompleted) / (ShareCourseTotal + SavedCourseTotal)) * 100
    return isNaN(percentage) ? 0 : Math.floor(percentage)
  }

  updateBadgeInfo() {
    if (this.props.profile && this.props.profile.BadgesVM) {
      this.setState({
        badgeTitle: this.props.profile.BadgesVM.ScorePoints.BadgesMaster.Title,
        scorePoints: this.props.profile.BadgesVM.ScorePoints.ScoredPoints
      });
    }
  }
  
  componentDidMount() {
    this.updateBadgeInfo();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.updateBadgeInfo();
    }
  }
  
  

  render() {
    const doughnutOptions = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        emptyDoughnut: {
          color: 'rgba(255, 128, 0, 0.5)',
          width: 2,
          radiusDecrease: 20
        },
        tooltip: {
          enabled: false
        },
        legend: {
          display: false,
        },
      },
    }
    const doughnutData = {
      datasets: [
        {
          data: [this.CountTotalCourse(), this.CountProgressCourse()],
          backgroundColor: [
            '#FFFFFF',
            '#07367B',
          ],
          borderColor: [
            '#FFFFFF',
            '#07367B',
          ],
          borderWidth: 1,
        },
      ],
    };
    let showing = true;
    const { t } = this.props;
    const isRightToLeft = document.body.dir === "rtl";
    // let proPic = this.props.props.profile.ProfileImageUrl != null ? this.props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? this.props.props.profile.ProfileImageUrl : "assets/profile-icon.jpg" : "assets/profile-icon.jpg";
    return (this.props.BlueBar ? (
      <div class="container-fluid mt-50 blue-dbbar-con">
        <div class="lms-bluecontainer-sec">
          <div className={`blue-con ${isRightToLeft ? "blue-bar-bl" : "blue-bar-br"}`}>
            <div className="lms-l d-flex justify-content-between">
              <div className="d-flex align-items-center lms-flex-l">
                <div className="blue-profile-wd col-2 d-flex">
                  <div className="blue-image1">
                    {/* {
                      <Doughnut
                        className='canvas-doughnut'
                        data={doughnutData}
                        options={doughnutOptions}
                      />
                    } */}
                    {
                      <img src={this.props.profile.ProfileImageUrl? this.props.profile.ProfileImageUrl : "assets/profile-icon.jpg"}/>
                    }
                  </div>
                </div>
                <div className="profile-para lms-l-c2">
                  <h1 className="profile-heading">Hello {this.props.profile.FirstName} </h1>
                  <p>{this.state.badgeTitle +' ' + this.state.scorePoints} Points</p>
                  {/* <NavLink to="/profile" className="viewdetails-text">
                    {t("View_Details_Label")}<svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.52405 2.83535L0.440915 0.06785C0.3399 -0.0227707 0.176354 -0.0226184 0.0755078 0.0683188C-0.02526 0.159244 -0.0249996 0.306537 0.0760286 0.397228L2.97564 3.00001L0.0759244 5.60278C-0.0250908 5.69349 -0.0253512 5.84068 0.0754037 5.93162C0.125957 5.97721 0.192185 6 0.258413 6C0.324472 6 0.39044 5.97736 0.440902 5.93209L3.52405 3.16466C3.5727 3.12109 3.6 3.06179 3.6 3.00001C3.6 2.93823 3.57262 2.879 3.52405 2.83535Z" fill="white" /></svg>
                  </NavLink> */}
                </div>
              </div>
            </div></div>
          <div className={`blue-con b-lg-0 ${isRightToLeft ? "blue-bar-bl" : "blue-bar-br"}`}> <div className="lms-m">
            <div className="d-flex align-items-lg-center lms-flex-m">
            <div className="blue-image-wd">
                <img src="assets/Ongoing.png" alt="lms-logo" />
              </div>
              <div className="col-lg-8">
                <p className="progress-statistics-text">
                  On going Courses
                </p>
                <h2 className="blue-bar-h2">{this.props.SavedCourseProgress + this.props.ShareCourseProgress}</h2>
              </div>
            </div>
          </div></div>
          <div className={`blue-con ${isRightToLeft ? "blue-bar-bl" : "blue-bar-br"}`}> <div className="lms-m">
            <div className="d-flex align-items-center lms-flex-m">
              <div className="blue-image-wd">
                <img src="assets/Percentage.png" alt="lms-logo" />
              </div>
              <div className="col-lg-8">
                <p className="progress-statistics-text">Complete percentage</p>
                <h2 className="blue-bar-h2">{this.completedToTotalCoursePercentage()}%</h2>
              </div>
            </div>
          </div></div>
          <div id="satics-res" className="blue-con b-0" > <div className="lms-r">
            <div className="d-flex align-items-center">
              <div className="blue-image-wd">
                <img src="assets/Course complete.png" alt="lms-logo" />
              </div>
              <div className="col-lg-8">
                <p className="progress-statistics-text">Courses Completed</p>
                <h2 className="blue-bar-h2">{this.props.ShareCourseCompleted + this.props.SaveCourseCompleted }</h2>
              </div>
            </div>
          </div></div>
        </div>
      </div>
    ) : (null)
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleBlueBar: blueBarAction,
    },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  BlueBar: state.bluebar.bluebar,
  profile: state.profileData,
  BlueBarCourse: state.bluebar.course,
  ShareCourseTotal: state.courses.courseReports.ShareCourseProgress,
  ShareCourseProgress: state.courses.courseReports.ShareCourseProgress,
  SavedCourseTotal: state.courses.courseReports.SavedCourseTotal,
  SavedCourseProgress: state.courses.courseReports.SavedCourseProgress,
  SaveCourseCompleted: state.courses.courseReports.SaveCourseCompleted,
  ShareCourseCompleted: state.courses.courseReports.ShareCourseCompleted,
  CourseReportLoad: state.courses.courseReports.courseReportLoad,
});
export const BlueBarTranslated = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BlueBar))
