import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { postTutorialVideo, videoUploadCheck, videoUploadChecktrue } from "../store/actions/admin";
import UploadFilesLabel from "../../../components/TranslatedComponents/UploadFilesLabel";
import BrowserLabel from "../../../components/TranslatedComponents/BrowserFile";

function UploadVideo(props) {
  const [file, setFile] = useState([])
  const [preview, setPreview] = useState('')
  const [displayUploadVideo, setDisplayUploadVideo] = useState(true)
  const [created, setCreated] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(sessionStorage.getItem('percentage'))
  const [alert, setAlert] = useState({
    success: false,
    message: '',
    type: ''
  })
  const [vrChecked, setVrChecked] = useState(false)

  const inputRef = useRef(null);
  const { isUploadDone, getFiles } = props
  const prevAmountIsUploadDone = usePreviousIsUploadDone(isUploadDone);
  const prevAmountGetFiles = usePreviousGetFiles(getFiles);

  function usePreviousIsUploadDone(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  function usePreviousGetFiles(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = event => {
    const fileObj = event.target.files;

    if (props.onFileSelectionCompleted) {
      props.onFileSelectionCompleted();
    }
    if (!fileObj) {
      return;
    }
    props.getFiles(fileObj[0])
    setFile([...fileObj]);
    props.hasFiles(true);


    generateVideoThumbnail(URL.createObjectURL(fileObj[0]))
    const { postTutorialVideoAction, videoUploadCheckAction } = props;

    if (fileObj.length > 0 && !isUploadDone && !created) {
      postTutorialVideoAction({
        file: fileObj[0],
        IsVrEnabled: vrChecked
      });
      setCreated(true)
      videoUploadCheckAction();
    }
  }

  useEffect(() => {
    if (prevAmountIsUploadDone !== isUploadDone) {
      clearAll()
      setCreated(false)
      setAlert({
        success: true,
        message: 'Video Uploaded Succecfully',
        type: 'success'
      })
    }
  }, [isUploadDone])

  const clearAll = () => {
    const { videoCreatedCheckAction } = props;
    setFile({})
    setPreview('')
    setAlert({
      success: false,
      message: '',
      type: ''
    })
    sessionStorage.removeItem('percentage')
    videoCreatedCheckAction()
  }

  const handleUploadVideo = () => {
    setDisplayUploadVideo(false)
  }

  const generateVideoThumbnail = async (src) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.currentTime = 100;
    video.autoplay = true;
    video.muted = true;
    video.src = src;

    video.onloadeddata = async () => {
      let ctx = canvas.getContext("2d");
      props.videolength(video.duration)

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      setPreview(canvas.toDataURL("image/png"))

      const byteCharacters = await urltoFile(canvas.toDataURL("image/png"), 'thumbnail.png', 'image/png')
      props.getThumbnail(byteCharacters)
    };
  };

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const handleVrChange = () => {
    setVrChecked(!vrChecked)
  }

  const handleFileClear = () =>{
    setFile({})
  }

  return (
    <React.Fragment>
      <section id="video-up" className="container video-upload-con">
        {!file.length > 0 &&
          <div className="video-upload video-upload-position">
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              <svg width="64" height="64" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg" className="upload-icon">
                <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu" />
              </svg>
              <p className="upload-file">
                {/* <b>Upload Files</b> */}
                <UploadFilesLabel />
              </p>
              <p style={{ cursor: 'pointer' }}>
                <BrowserLabel />
              </p>
            </div>
            <div class="vr-toggle-row">
              <div align='right' class="vr-toggle-column">Normal Video</div>
              <div align='center' class="vr-toggle-column-center">
                <input
                  className="react-switch-checkbox"
                  id={`react-switch-new`}
                  type="checkbox"
                  checked={vrChecked}
                  onChange={handleVrChange}
                />
                <label
                  className="react-switch-label"
                  htmlFor={`react-switch-new`}
                >
                  <span className={`react-switch-button`} />
                </label>
              </div>
              <div align='left' class="vr-toggle-column">Virtual Reality Video
                {/* <Tooltip title={[<p>Please <a href="https://www.youtube.com/watch?v=YLb1gnmsceA" target="_blank">click here</a> to know about virtual reality videos.</p>]} placement="right-end"><InfoOutlinedIcon fontSize="small" color="grey" /></Tooltip> */}
              </div>
            </div>
            <p className="vr-info-para">Please <a href="https://www.youtube.com/watch?v=YLb1gnmsceA" target="_blank">click here</a> to know about virtual reality videos.</p>
            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              accept=".mp4"
              multiple
              onChange={handleFileChange}
            />
          </div>}
        {
          file.length > 0 && (
            file.map((i,index) => {
              return (
              <>
              <h4>Uploading {index+1} of {file.length} videos</h4>
                <div className="m-30 mt-30">
                  <li key={index}>
                  <aside>
                    <div className="upload-ststus-details lms-flex-start">
                      <div>
                        <input
                          type="radio"
                          checked/>
                      </div>
                      <div className="mx-4 col-2">
                        <img
                          className="video-thumb"
                          src={preview}
                          alt="video-thumbnail"
                        />
                      </div>
                      <div className="video-details">
                        <p className="p-0">{i.name}</p>
                        {/* <p className="p-0">
                          {sessionStorage.getItem('percentage') ? sessionStorage.getItem('percentage') : 0}%<span> 12 minutes remaining</span>
                        </p> */}
                      </div>
                      <img className = "delete-img" src= "assets/delete-img.jpg" />
                    </div>
                    {/* <Videoprogressbar progress={uploadProgress} /> */}

                  </aside>
                  </li>
                </div>
                </>
              );
            })
          )
        }

      </section>
    </React.Fragment>
  );
  // }
}

const mapStateToProps = (state) => ({
  progress: state.tutorials.progress,
});

const mapDispatchToProps = {
  postTutorialVideoAction: postTutorialVideo,
  videoUploadCheckAction: videoUploadCheck,
  videoCreatedCheckAction: videoUploadChecktrue
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideo);
