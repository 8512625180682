import React, { useState, useEffect } from 'react';

const VideoExpireTime = ({ expirationTime, onBorderColorChange }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const expireTime = new Date(expirationTime);
      const difference = expireTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);

        if (days > 0) {
          setTimeLeft(`${days} days left`);
        } else if (hours > 0) {
          setTimeLeft(`${hours} hours left`);
        } else {
          const minutes = Math.floor((difference / 1000 / 60) % 60);
          setTimeLeft(`${minutes} minutes left`);
        }

        // Pass the border color back to the parent
        onBorderColorChange(days < 1 ? '#DC5050' : '#3ADDC8');
      } else {
        setTimeLeft('Expired');
        onBorderColorChange('#DC5050');
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [expirationTime, onBorderColorChange]);

  return (
    <p>{timeLeft}</p>
  );
};

export default VideoExpireTime;
