import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import ProgressBar from "../Common/ProgressBar";
import { Avatar, Stack } from "@mui/material";
import GenerateProfilePic from "../Common/GenerateProfilePictureIntitals";
import ErrorTab from "../Common/ErrorTab";
import VideoExpireTime from "../Common/VideoExpireTime";
import VideoUploadedDate from "../Common/VideoUploadedDate";
export const SavedTutorialList = (props) => {
  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((tutorial, idx) => {
          const detailId = tutorial.VideoId;
          const [borderColor, setBorderColor] = React.useState('#3ADDC8');
          const borderStyle = tutorial.WatchExpirationDateTime ? { border: `2px solid ${borderColor}` } : {};
          return (
            <li className="card-space" key={idx} style={borderStyle}>
              <Link to={"/videos/" + detailId} id={"listcard" + idx}>
                <div className="card-img">
                  <img
                    src={tutorial.ThumbnailUrl}
                    alt="lms-logo" />
                </div>
                <ProgressBar value={tutorial.Percentage}></ProgressBar>
                <div className="card-info lms-flex-between">
                  <p>{tutorial.CategoryName}</p>
                  <div className="card-date">
                    {tutorial.WatchExpirationDateTime === null ?
                      <VideoUploadedDate createdDate={tutorial.CreatedDate} />
                      :
                      <VideoExpireTime
                        expirationTime={tutorial.WatchExpirationDateTime}
                        onBorderColorChange={setBorderColor}
                      />}
                  </div>
                </div>
                <div className="card-heading">{tutorial.Title}</div>
                <div className="card-para">{tutorial.Description}</div>
                {props.type !== 'save' && <div style={{ marginTop: 10 }}>
                  <Stack direction="row" spacing={2} >
                    {
                      props.type === 'sharebyyou' ?
                        <Avatar
                          alt="Remy Sharp"
                          src={tutorial.SharedWithProfilePictureUrl === '' || tutorial.SharedWithProfilePictureUrl === null ? GenerateProfilePic(tutorial.SharedWithName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/cdn' + tutorial.SharedWithProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                        :
                        <Avatar
                          alt="Remy Sharp"
                          src={tutorial.SharedByProfilePictureUrl === '' || tutorial.SharedByProfilePictureUrl === null ? GenerateProfilePic(tutorial.SharedByName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/cdn' + tutorial.SharedByProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                    }

                    {console.log(props.type)}
                    <div className="identify" style={{ marginLeft: 7 }}>{props.type === 'sharebyyou' ? tutorial.SharedWithName : tutorial.SharedByName}</div>
                  </Stack>
                </div>}
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return <ErrorTab class={"no-result-saved-item"} />
  }
};

export default SavedTutorialList;
