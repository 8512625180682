import React, { useEffect, useState, useRef } from "react";
import BreadcrumbsNav from "../Common/BreadcrumbsNav";
import HorizontalStepper from "../../../components/Common/HorizontalStepper";
import UploadedVideosEdit from "../Common/UploadedVideosEdit";
import UploadCourse from "./UploadCourse";
import { Backdrop, Alert } from '@mui/material';
import LoadingScreen from "../../../components/Common/LoadingScreen";
import AttachQuiz from "../Common/AttachQuiz";
import CoursePreviews from "../Common/CoursePreviews";
import CourseCreation from "../Common/CourseCreation";
import { CourseType } from "../Common/Constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateDocumentVideo, updateTutorialVideo } from "../store/actions/admin";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";
import { useNavigate } from "react-router-dom";

const CreateOfflineCourse = (props) => {

    const [activeStep, setActiveStep] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState({})
    const [successResposnse, setSuccessResposnse] = useState('');
    const [courseId, setCourseId] = React.useState(null);
    const [alert, setAlert] = useState({
        active: false,
        severity: '',
        message: '',
        loading: false
    })
    const [getFiles, setGetFiles] = useState([]);
    const [editedCourseData, setEditedData] = useState([])
    const [getQuizCourseList, setQuizCourseList] = useState([])
    const [mockFiles, setMockfiles] = useState([])
    const [thumbnailPreview, setPrivewThumbnail] = useState('');
    const [selectedQuiz, setSelectedQuiz] = useState([])
    const childRef = useRef();
    const navigate = useNavigate();

    const handleSuceessResponse = (e) => {
        setSuccessResposnse(e)
    }

    const triggerChildCreateCourse = () => {
        if (childRef.current) {
            childRef.current.triggerCreateLiveCourse();
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            triggerChildCreateCourse();
        } else if (activeStep === 3) {
            handleAddVideoToPlaylist();
            handleUpload()
        }
        if (activeStep > 0 && activeStep < 3) {
            setActiveStep(prevStep => prevStep + 1);
        }
    }

    const handleThumbnailPreview = (setPreview) => {
        setPrivewThumbnail(setPreview)
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: '',
            loading: false
        })
    };

    const handleCourseId = (id) => {
        setCourseId(id);
    };

    useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    const addFileToMockFiles = (newFile) => {
        setMockfiles((prevFiles) => [...prevFiles, newFile]);
    };

    const handleAddVideoToPlaylist = (e) => {
        const quizIds = getQuizCourseList
            .filter(item => item.Type === 0)
            .map(quiz => quiz.Id);
    
        const videoIds = getQuizCourseList
            .filter(item => item.Type === "video")
            .map(video => video.Id);

        const VideoapiCalls = videoIds.map(VideoId => {
            return API.addVideoToPlaylist({
                PlaylistId: courseId,
                VideoId: VideoId,
            });
        });
    
        const QuizApicalls = quizIds.map((QuizId, index) => {
            return API.assignQuiztoPlaylist({
                quizId: QuizId,
                playlistId: courseId,
                indexSequence: index + 1
            });
        });
    
        const apiCalls = [...VideoapiCalls, ...QuizApicalls];
    
        Promise.all(apiCalls)
            .then(responses => {

                const allSuccess = responses.every(res => res.status === 200);
    
                if (allSuccess) {
                    UserAPI.fetchCoursesDetail(courseId)
                        .then(res => {
                            if (res.status === 200) {
                                setAlert({
                                    active: true,
                                    severity: 'success',
                                    message: 'Live course created successfully',
                                });
                                navigate('/collection/videocollection');
                            }
                        })
                        .catch(err => {
                            setAlert({
                                active: true,
                                severity: 'error',
                                message: 'Failed to fetch course details',
                            });
                        });
                }
            })
            .catch(err => {
                setAlert({
                    active: true,
                    severity: 'error',
                    message: 'Failed to add videos or quizzes to playlist',
                    loading: false,
                });
            });
    };
    

    const handleUpload = () => {
        API.updateVideoData({
            Id: mockFiles[0].VideoId,
            Name: editedCourseData.videoTitle.trim(),
            Title: editedCourseData.videoTitle.trim(),
            CategoryId: parseInt(editedCourseData.videoCategoryId),
            Description: editedCourseData.videoDescription.trim(),
        })
    };


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="p-5 mt-5 bg-white">
                        <CourseCreation
                            ref={childRef}
                            setAlert={setAlert}
                            activeStep={activeStep}
                            onCourseIdReceived={handleCourseId}
                            setCourseType={CourseType.Offline}
                            setActiveStep={setActiveStep} />
                    </div>
                );
            case 1:
                return (
                    <div>
                        {mockFiles.length === 0 ? (
                            <>
                                <UploadCourse
                                    setPreviewThumb={handleThumbnailPreview}
                                    updateMockFiles={addFileToMockFiles}
                                    selectVideo={(e) => setSelectedVideo(e)}
                                    successResponse={successResposnse}
                                    getFiles={(e) => setGetFiles(e)}
                                    setAlert={setAlert}
                                />
                                {alert.loading && <LoadingScreen />}
                            </>
                        ) : (
                            <UploadedVideosEdit
                                mockFiles={mockFiles}
                                selectedVideo={selectedVideo}
                                editedData={setEditedData}
                                selectedPlaylist={getQuizCourseList}
                                thumbnailPreview={thumbnailPreview}
                                uploadedCourseId={courseId}
                            />
                        )}
                    </div>

                );
            case 2:
                return (
                    <div>
                        <AttachQuiz
                            setQuizCourseList={setQuizCourseList}
                            mockFiles={mockFiles}
                            setSelectedQuiz={setSelectedQuiz}
                            thumbnailPreview={thumbnailPreview} />
                    </div>
                );
            case 3:
                return (
                    <div className="mt-3">
                        <CoursePreviews
                            thumbnailPreview={thumbnailPreview}
                            getQuizCourseList={getQuizCourseList}
                            mockFiles={getQuizCourseList} />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>Unknown Step</h1>
                    </div>
                );
        }
    };
    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {alert.message}
                </Alert>
            </Backdrop>
            <div className="video-tab-con">
                <BreadcrumbsNav
                    activeTtile="Create Offline Course"
                    setStudioLandingPage={props.setStudioLandingPage}
                />
                <HorizontalStepper className="mt-3" steps={['Course Details', 'Upload Video', 'Quiz', 'Preview']} activeStep={activeStep} />
                <div className='d-flex justify-content-center studio-course-creation'>
                    <div className='upload-con-width'>
                        {renderStepContent(activeStep)}
                        <div className="fixed-btns d-flex justify-content-between">
                            <button onClick={handleBack} disabled={activeStep === 0} className="filter-clear-all m-0">Back</button>
                            <button onClick={handleNext} className="filter-clear-search">Next</button>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(
        {
            updateTutorialVideoAction: updateTutorialVideo,
            updateDocumentVideoAction: updateDocumentVideo,
        },
        dispatch
    );
};

const mapStateToProps = (state) => ({
    VideoData: state.tutorials.VideoData,
    DocumentData: state.tutorials.DocumentData,
    VideoName: state.tutorials.VideoName,
    VideoExtension: state.tutorials.VideoExtension,
    DocumentExtension: state.tutorials.DocumentExtension,
    DocumentName: state.tutorials.DocumentName,
    isVideoUploaded: state.tutorials.isVideoUploaded,
    courses: state.courses.courses.Playlist,
    playlistCategories: state.category.playlistCategories,
});


export default connect(mapStateToProps, mapDispatchToProps)(CreateOfflineCourse);