import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
export default function HorizontalStepper({ activeStep, steps }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper className='mui-stepper' activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel><h4>{label}</h4></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
