import GenerateProfilePic from "../../components/Common/GenerateProfilePictureIntitals";
import actions from "../actions/actionTypes";

let initialState = {
    Name: "",
    FirstName: "",
    LastName: "",
    id: "",
    ProfileImageUrl: "",
    Role: "",
    RoleId: null,
    Email: "",
    LanguageId: null,
    LanguageName: "",
    loaded:false,
    BadgesVM:""
};

const profileDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_PROFILE_DATA_SUCCEEDED: {
            console.log(action);
            console.log(state);
            return {
                ...state,
                Name: action.profileData.Name,
                FirstName: action.profileData.FirstName,
                LastName: action.profileData.LastName,
                id: action.profileData.Id,
                ProfileImageUrl: action.profileData.ProfileImageUrl == null || action.profileData.ProfileImageUrl == "https://lmsstoragepoc.blob.core.windows.net/cdn" || action.profileData.ProfileImageUrl.length <= 0 ? GenerateProfilePic(action.profileData.Name) : action.profileData.ProfileImageUrl,
                Role: action.profileData.Role,
                RoleId: action.profileData.RoleId,
                Email: action.profileData.Email,
                LanguageId: action.profileData.LanguageId,
                LanguageName: action.profileData.LanguageName,
                loaded: true,
                BadgesVM: action.profileData.BadgesVM,
                PreferredCategories:action.profileData.PreferredCategories
            };
        }
        case actions.GET_PROFILE_DATA_FAILED: {
            return {
                ...state,
                loaded: false,
            }
        }
        default:
            return state;
    }
}

export default profileDataReducer;