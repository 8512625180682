import React from "react";
import ProfileTabs from "./ProfileTabs";
import { useNavigate, useParams } from "react-router";


export default function Profile() {
  const {index} =useParams()
  const navigate = useNavigate()
  

  return <div>

    <ProfileTabs index={index}></ProfileTabs>
   
    
  </div>;
}
