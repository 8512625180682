import React from "react";

export default function ScoreLevelMap(props) {
    console.log(props.props.profile.BadgesVM.ScorePoints.ScoredPoints)
    const levelData = [{
        id: 0,
        badgeImg: "assets/badge-0-100.png",
        title: "Newbe",
        scoreStart: 0,
        scoreEnd: 100,
    },
    {
        id: 1,
        badgeImg: "assets/badge-101-200.png",
        title: "Explorer",
        scoreStart: 101,
        scoreEnd: 200,
    },
    {
        id: 2,
        badgeImg: "assets/badge-201-300.png",
        title: "Challenger",
        scoreStart: 201,
        scoreEnd: 300,
    },
    {
        id: 3,
        badgeImg: "assets/badge-301-400.png",
        title: "Achiever",
        scoreStart: 301,
        scoreEnd: 400,
    },
    {
        id: 4,
        badgeImg: "assets/badge-401-500.png",
        title: "Innovator",
        scoreStart: 401,
        scoreEnd: 500,
    },
    {
        id: 5,
        badgeImg: "assets/badge-501-600.png",
        title: "Strategist",
        scoreStart: 501,
        scoreEnd: 600,
    },
    {
        id: 6,
        badgeImg: "assets/badge-601-700.png",
        title: "Expert",
        scoreStart: 601,
        scoreEnd: 700,
    },
    {
        id: 7,
        badgeImg: "assets/badge-701-800.png",
        title: "Leader",
        scoreStart: 701,
        scoreEnd: 800,
    },
    {
        id: 8,
        badgeImg: "assets/badge-801-900.png",
        title: "Legend",
        scoreStart: 801,
        scoreEnd: 900,
    },
    ];

    return (
        <section className="score-card">
            <ul>
                {levelData.map((eachItem) => {
                    return (
                        <li
                            key={eachItem.id}
                            className="d-flex gap-2"
                            style={{
                                opacity: props.props.profile.BadgesVM.ScorePoints.ScoredPoints >= eachItem.scoreStart ? 1 : 0.4
                            }}>
                            <img src={eachItem.badgeImg} alt={eachItem.title} />
                            <div>
                                <h2>{eachItem.title}</h2>
                                <p>{eachItem.scoreStart + "-" + eachItem.scoreEnd}</p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}
