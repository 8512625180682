import axios from "axios";

const sessionCreateLogicAppRequest = payload => {
  var instance = axios.create();
  delete instance.defaults.headers.common["Authorization"];

  return instance
    .post(process.env.REACT_APP_SESSION_PROVIDER_SERVER, payload)
    .catch(err => console.warn(err));
};

export { sessionCreateLogicAppRequest };
