import React from "react";
import { Route, Routes, BrowserRouter as Router, useNavigate } from "react-router-dom";
import App from "../modules/App";
import Profile from "../modules/User/Profile";
import TutorialDetail from "../components/TutorialsList/TutorialDetail";
import Login from "../components/AuthComponents/Login";
import SearchResponsive from "../components/SearchResponsive";
import Courses from "../components/CoursesList/Courses";
import Videos from "../components/VideosList/Videos";
import CourseDetail from "../components/CoursesList/CourseDetail";
import AdminDashboard from "../modules/Admin/AdminDashboard";
import Layout from "../modules/layout";
import EditUserProfile from "../modules/User/EditUserProfile";
import BrowseBookmarked from "../modules/Browse/BrowseBookmarked"
import BrowseSharedByYou from "../modules/Browse/BrowseSharedByYou"
import BrowseSharedWithYou from "../modules/Browse/BrowseSharedWithYou"
import CourseDatagriddata from "../modules/Admin/Video/CourseDatagriddata";
import VideoDatagriddata from "../modules/Admin/Video/VideoDatagriddata";
import UserDataGridData from "../modules/Admin/UserData/Datagriddata";
import QuizDataGrid from "../modules/Admin/Quiz/QuizDataGrid";
import QuizDataGridData from "../modules/Admin/Quiz/QuizDataGrid";
import Analytics from "../modules/Admin/Common/Analytics";
import CertificatesList from "../modules/User/CertificatesList";
import TermsAndConditions from "../components/Common/TermsAndConditions";
import PrivacyAndPolicy from "../components/Common/PrivacyAndPolicy";
import PageNotFound from "../components/Common/PageNotFound";
import { connect } from "react-redux";
import Logout from "../components/AuthComponents/logout";
import { useFusionAuth } from "@fusionauth/react-sdk";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import Settings from "../modules/Admin/Common/Settings";
import UserAnalytics from "../modules/User/UserAnalytics";
import CourseFirst from "../modules/Admin/Course/CourseFirst";
import CollectionData from "../modules/Admin/CollectionData";
import UserManagement from "../modules/Admin/UserManagement";
import Studio from "../modules/Admin/Common/Studio";


const AppRouter = (props) => {
  const { isAuthenticated } = useFusionAuth()
  const history = createBrowserHistory()
  const ten = window.location.pathname.split('/')[1]
  const basename = props.tenant.tenant.tenantRouteName === undefined ? ten : props.tenant.tenant.tenantRouteName

  if (!props.tenant.loaded) {
    <>
      <h1>
        Loading.....
      </h1>
    </>
  }
  else {
    return (
      <Router basename={`/${basename}`}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<App />} />
            <Route path="browsesharedbyyou" element={<BrowseSharedByYou />} />
            <Route path="browsesharedwithyou" element={<BrowseSharedWithYou />} />
            <Route path="profile" element={<Profile />}>
              <Route path="userinfo" element={<Profile />} />
            </Route>
            <Route path="certificates" element={<CertificatesList />} />
            <Route path="myAnalytics" element={< UserAnalytics />} />
            <Route path="videos" element={<Videos />} />
            <Route path="videos/:id" element={<TutorialDetail />} />
            <Route path="SearchResponsive" element={<SearchResponsive />} />
            <Route path="courses" element={<Courses />} />
            <Route path="course/:id/:videoId" element={<CourseDetail />} />
            <Route path="assessment" element={<AdminDashboard />} >
              <Route path="quiz" element={<QuizDataGrid />} />
              <Route path="question" element={<QuizDataGrid />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route path="studio" element={<Studio />} />
            <Route path="collection" element={<CollectionData />} >
              <Route path="videocollection" element={<VideoDatagriddata />} />
              <Route path="coursecollection" element={<CourseDatagriddata />} />
            </Route>
            <Route path="userManagement" element={<UserManagement />} >
              <Route path="users" element={<UserDataGridData />} />
              <Route path="groups" element={<UserDataGridData />} />
            </Route>
            <Route path="analytics" element={<Analytics />} />
            <Route path="edituserprofile" element={<EditUserProfile />} />
          </Route>
          <Route path="terms&conditions" element={<TermsAndConditions />} />
          <Route path="privacy&policy" element={<PrivacyAndPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    )
  }
};


const mapStateToProps = (state) => ({
  tenant: state.tenant
});

export default connect(mapStateToProps)(AppRouter);
