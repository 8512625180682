import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const NoCourseAndVideos = (props) => {
    const [buttonName, setButtonName] = useState('Upload Video')

    useEffect(() => {
        if (window.location.pathname.includes("coursecollection")) {
            setButtonName('Create Course')
        } else if (window.location.pathname.includes("videocollection")) {
            setButtonName('Upload Video')
        }
    })

    return (
        <section id="no-courses">
            <img src="assets/no-videos-courses-img.jpg" alt="No Data" />
            <p>No Results found for the course you searched, start creating the course now.</p>
            <NavLink to={"/studio"} ><button>{buttonName}</button></NavLink>
        </section>
    )
}

export default NoCourseAndVideos;