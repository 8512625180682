import React from 'react'
import { useTranslation } from 'react-i18next';

export default function LogoutMenulabel() {
    const { t } = useTranslation();
    return (
        <p className='red-logout-p'>
            {t("Logout_Menu_Label")}
        </p>
    );
}
