import React, { Component, useEffect, useState } from "react";
import { MsalContext } from "@azure/msal-react";
import { msalConfig, loginRequest } from "../../utils/authConfig";
import { getUserDetails } from "../../store/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { fetchOrganisationDetailAction } from "../../store/actions/OrganistaionAction";
import {
  FusionAuthLoginButton,
  FusionAuthLogoutButton,
  RequireAuth,
  useFusionAuth
} from '@fusionauth/react-sdk';
import API from "../../modules/Admin/utils/API";
import { fetchTenantDetailsAction } from "../../modules/Admin/store/actions/admin";

function LoginFuc(props) {
  const [authenticated, setAuthenticated] = useState(false)
  let searchParams = new URLSearchParams(window.location.search);
  const uri = searchParams.get("redirectUri");
  const { isAuthenticated, user, login } = useFusionAuth();

  if (authenticated && user) {
    props.getUserDetails(user)
    props.fetchTenant(user.tid)
    return <Navigate to={`/`} />;
  }
  else {
    return (
      <div className="loginform">
        <div>
          <section className="login-section">
            <section className="login-image-section">
              <div className="login-image-container">
                <img
                  src="assets/login-page-image.png"
                  alt="login-page-image"
                  className="login-page-image"
                />
              </div>
            </section>
            <section className="login-details-section">
              <div className="login-details-container">
                <div className="logo-container">
                  <img src="../assets/skolasti-logo.png" alt="Logo" className="logo" />
                </div>
                <div className="login-form-section">
                  <div className="button-container">
                    <button type="submit" className="login-button" onClick={() => login()}>
                      Login
                    </button>
                  </div>
                  {/* <div className="sign-container">
                    <p className="sign-up">
                      Don’t have an account?{" "}
                      <a href="" className="sign-up-span">
                        Sign up
                      </a>
                    </p>
                  </div> */}
                  <div className="login-img-2">
                    <img src = "../assets/login-img-2.png" alt = "login-img-2"/>
                  </div>
                </div>
              </div>
            </section>

          </section>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  profile: state.user,
  tenant: state.tenant
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserDetails: getUserDetails,
      fetchOrganisation: fetchOrganisationDetailAction,
      fetchTenant: fetchTenantDetailsAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFuc);
