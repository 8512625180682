import SavedTutorialList from "../../components/TutorialsList/SavedTutorialList";
import SavedCoursesList from "../../components/CoursesList/SavedCourseList";
import { useState, useEffect } from "react";
import Spinner from "../../components/Common/Spinner";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import store from "../../store/configureStore";
import { useSelector } from "react-redux";

function BrowseBody(props) {
  const { t } = useTranslation()

  const Videos = t("Videos_Label")
  const Courses = t("Courses_Label")
  const ViewAll = t("View_All")


  const [viewAllCourse, setviewAllCourse] = useState(false);
  const [viewAllVideos, setviewAllVideos] = useState(false);
  const { videoLazyloader, courseLazyLoader } = props.props;
  const bluebar = useSelector(()=> store.getState().bluebar.bluebar);
  const setViewAllVideo = () => {
    setviewAllVideos(!viewAllVideos);
  }

  const setViewAllCourse = () => {
    setviewAllCourse(!viewAllCourse);
  }

  const getFilterData = () => {
    const { tutorials } = this.props;

    return tutorials?.filter(
      (item) =>
        item?.Title?.toLowerCase().match(this.state.search.toLowerCase()) ||
        item?.CategoryName?.toLowerCase().match(
          this.state.search.toLowerCase()
        ) ||
        item?.Description?.toLowerCase().match(this.state.search.toLowerCase())
    );
  }
  return (
    <>
      {!viewAllCourse && (
        <section id="cards-grid-browse">
          <div className="view-all-content">
            {(!viewAllVideos) ? (
              props.tutorials.Count > 5 ?
                (<div className="lms-flex-between">
                  <span className="view-heading">{Videos}</span>
                  <h6 className="view-all" onClick={setViewAllVideo}>
                    {ViewAll}{" "}
                    <span>
                      <svg width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                      </svg>
                    </span>
                  </h6>
                </div>) : (<span className="view-heading">{Videos}</span>)
            ) : (
              <div onClick={setViewAllVideo}>
                <span className="view-heading-backarrow">{<svg className="viewall-image" width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                </svg>}</span>{" "}
                <span className="view-heading-back">{` ${Videos}`}</span>
              </div>
            )}
          </div>
          {console.log(props.searchValue.length > 0 ? true : false)}

          {
            viewAllVideos ?
              <InfiniteScroll
                style={{ overflow: 'hidden' }}
                dataLength={props.searchValue.length > 0 ? props.filterData.Count : props.tutorials.Videos.length}
                next={props.handleBrowseVideoScroll}
                hasMore={props.videoHasMore}
                loader={props.tutorials.Count > props.tutorials.Videos.length && <Spinner />}
                endMessage
              >
                <ul className="card-grid">
                  <SavedTutorialList
                    props={props.searchValue.length > 0 ? props.filterDataVideo.Videos : props.tutorials.Videos}
                    type={props.type}
                  />
                </ul>
              </InfiniteScroll>
              :
              <ul className="card-grid">
                <SavedTutorialList
                  props={props.searchValue.length > 0 ? props.filterDataVideo.Videos.slice(0, 5) : props.tutorials.Videos.slice(0, 5)}
                  type={props.type}
                />
              </ul>
          }
        </section>
      )}
      {!viewAllVideos && (
        <section id={`${viewAllCourse && "cards-grid-browse"}`} className={`cards-grid-browse ${viewAllCourse ? "view-all-space" : "m-0"}`}>
          <div className="view-all-content">
            {!viewAllCourse ? (
              props.courses.Count > 5 ?
                (<div className="lms-flex-between">
                  <span className="view-heading">{Courses}</span>
                  <h6 className="view-all" onClick={setViewAllCourse}>
                    {ViewAll}{" "}
                    <span>
                      <svg width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                      </svg>
                    </span>
                  </h6>
                </div>) : (<span className="view-heading">{Courses}</span>)

            ) : (
              <div onClick={setViewAllCourse}>
                <span className="view-heading-backarrow">{<svg className="viewall-image" width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                </svg>}</span>{" "}
                <span className="view-heading-back">{` ${Courses}`}</span>
              </div>
            )}
          </div>
          {
            viewAllCourse ?
              <InfiniteScroll
                style={{ overflow: 'hidden' }}
                dataLength={props.searchValue.length > 0 ? props.filterData.Count : props.courses.Playlist.length}
                next={props.handleBrowseCourseScroll}
                hasMore={props.courseHasMore}
                loader={props.courses.Count > props.courses.Playlist.length && <Spinner />}
                endMessage
              >
                <ul className="card-grid">
                  <SavedCoursesList
                    props={props.searchValue.length > 0 ? props.filterCourseData.Playlist : props.courses.Playlist}
                    type={props.type}
                  />
                </ul>
              </InfiniteScroll>
              :
              <ul className="card-grid">
                <SavedCoursesList
                  props={props.searchValue.length > 0 ? props.filterCourseData.Playlist.slice(0, 5) : props.courses.Playlist.slice(0, 5)}
                  type={props.type}
                />
              </ul>
          }
        </section>
      )}
    </>
  )
}
export default BrowseBody;