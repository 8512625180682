import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BreadcrumbsNav({activeTtile,setStudioLandingPage}) {
    function handleClick(event) {
        event.preventDefault();
        setStudioLandingPage(null);
    }
    return (
        <Stack spacing={2} className='mb-4'>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link underline="hover" key="1" color="inherit" href="/studio" onClick={handleClick}>
                    Studio
                </Link>
                <Typography key="3" sx={{ color: 'text.primary' }}>
                    {activeTtile}
                </Typography>
            </Breadcrumbs>
        </Stack>
    );
}
