import React, { Component } from "react";
import UserNameLabel from "../../components/TranslatedComponents/UserNameLabel";
import ProfileMenuLabel from "../../components/TranslatedComponents/ProfileMenuLabel";
import HandleLogout from "../../components/AuthComponents/logout"
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { MsalContext } from "@azure/msal-react";
import LogoutMenulabel from "../../components/TranslatedComponents/LogoutMenulabel";
import { FusionAuthContext } from "@fusionauth/react-sdk";
import { signOutUser } from "../../store/actions/userActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProfileData } from "../../store/actions/profileDataAction";

export class UserDropDown extends Component {
  static contextType = FusionAuthContext;

  container = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.logout = this.handleLogout.bind(this);

  }

  handleButtonClick() {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };


  handleLogout() {
    this.context.logout()
      .then(e =>
        this.props.signOutAction()
      )
      .catch(e => {
        console.error(e);
      });
  };

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    // let proPic = this.props.props.props.user.ProfileImageUrl != null ? this.props.props.props.user.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? this.props.props.props.user.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');
    return (

      <div className="user-details lms-flex-between">
        <div >
        <div className="profile-title">{this.props.name}</div>
        <div className="profile-title profile-badges">{this.props.badgeTitle} | {this.props.scorePoint} Points <div><img src="assets/explorer-badge.png" alt="badge" /></div></div>
        </div>
        <div className="profile-drop-container" ref={this.container}>
          <div className="profile">
            <img src={this.props.profileImg} alt="lms-logo" onClick={this.handleButtonClick} />
          </div>
          {/* <button type="button" >
            <img src="assets/path.png" alt="lms-logo" />
          </button> */}
          {this.state.open && (<div className={`profileDropDown ${document.body.dir == 'rtl' ? 'ar-profile-drop' : 'en-profile-drop'}`}>
            <ul className="ulProfile">
              <li className="liMenu">
                <NavLink to={"/profile/userinfo"} className=" lms-flex-align-center ">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 0.500977C3.58218 0.500977 0 4.08246 0 8.50063C0 12.9188 3.58183 16.5003 8 16.5003C12.4185 16.5003 16 12.9188 16 8.50063C16 4.08246 12.4185 0.500977 8 0.500977ZM8 2.89296C8.70178 2.89292 9.37483 3.17165 9.87109 3.66785C10.3674 4.16405 10.6462 4.83706 10.6462 5.53884C10.6463 6.24062 10.3675 6.91367 9.87134 7.40993C9.37514 7.9062 8.70213 8.18502 8.00035 8.18507C7.29857 8.18511 6.62552 7.90638 6.12926 7.41018C5.633 6.91398 5.35417 6.24097 5.35412 5.53919C5.35408 4.83741 5.63281 4.16436 6.12901 3.6681C6.62521 3.17184 7.29822 2.89301 8 2.89296ZM7.99824 14.4087C6.59639 14.4103 5.24023 13.9102 4.175 12.9989C4.05103 12.8931 3.95147 12.7617 3.88316 12.6138C3.81485 12.4658 3.77942 12.3048 3.77932 12.1418C3.77932 10.66 4.97865 9.47401 6.46086 9.47401H9.53984C11.0224 9.47401 12.2172 10.66 12.2172 12.1418C12.2173 12.3048 12.182 12.4657 12.1137 12.6137C12.0454 12.7616 11.9459 12.8929 11.8218 12.9986C10.7567 13.9102 9.4003 14.4105 7.99824 14.4087Z" className="user-drop-svg"/>
                  </svg>
                  <ProfileMenuLabel />
                </NavLink>
                {/* </a> */}
              </li>
              <li className="liMenu lms-flex-align-center" onClick={this.logout}>
              <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3546 15.75C10.3546 15.9489 10.2756 16.1397 10.1349 16.2803C9.99426 16.421 9.8035 16.5 9.60458 16.5H2.53418C1.87137 16.4993 1.23591 16.2356 0.767235 15.7669C0.298555 15.2983 0.0349241 14.6628 0.0341797 14V3C0.0349241 2.33719 0.298555 1.70173 0.767235 1.23306C1.23591 0.764376 1.87137 0.500744 2.53418 0.5H9.60458C9.8035 0.5 9.99426 0.579018 10.1349 0.71967C10.2756 0.860322 10.3546 1.05109 10.3546 1.25C10.3546 1.44891 10.2756 1.63968 10.1349 1.78033C9.99426 1.92098 9.8035 2 9.60458 2H2.53418C2.26905 2.00029 2.01487 2.10574 1.82739 2.29321C1.63992 2.48069 1.53447 2.73487 1.53418 3V14C1.53446 14.2651 1.63991 14.5193 1.82738 14.7068C2.01486 14.8943 2.26905 14.9997 2.53418 15H9.60458C9.8035 15 9.99426 15.079 10.1349 15.2197C10.2756 15.3603 10.3546 15.5511 10.3546 15.75ZM13.7469 7.96969L10.1828 4.40556C10.1134 4.33464 10.0307 4.27819 9.93935 4.23946C9.84802 4.20074 9.74992 4.18052 9.65072 4.17997C9.55153 4.17942 9.45321 4.19855 9.36146 4.23626C9.26971 4.27397 9.18635 4.32951 9.11621 4.39966C9.04607 4.4698 8.99054 4.55317 8.95284 4.64492C8.91515 4.73668 8.89602 4.835 8.89658 4.9342C8.89715 5.03339 8.91738 5.13149 8.95611 5.22282C8.99485 5.31414 9.05131 5.39687 9.12224 5.46622L11.4059 7.75H5.50165C5.30274 7.75 5.11197 7.82902 4.97132 7.96967C4.83067 8.11032 4.75165 8.30109 4.75165 8.5C4.75165 8.69891 4.83067 8.88968 4.97132 9.03033C5.11197 9.17098 5.30274 9.25 5.50165 9.25H11.4059L9.12212 11.5337C9.05216 11.6033 8.99662 11.686 8.95868 11.7771C8.92074 11.8682 8.90114 11.9658 8.901 12.0645C8.90087 12.1632 8.9202 12.2609 8.9579 12.3521C8.99559 12.4432 9.05091 12.5261 9.12067 12.5958C9.19044 12.6656 9.27328 12.7209 9.36446 12.7586C9.45564 12.7963 9.55336 12.8157 9.65202 12.8155C9.75069 12.8154 9.84835 12.7958 9.93943 12.7578C10.0305 12.7199 10.1132 12.6644 10.1828 12.5944L13.7469 9.03034C13.8875 8.88969 13.9665 8.69893 13.9665 8.50002C13.9665 8.3011 13.8875 8.11034 13.7469 7.96969Z" className="svg-red-fill"/>
                </svg>

                <LogoutMenulabel />
                {/* </a> */}
              </li>
              {/* <li className="liMenu"><a href="/" className="lms-flex-align-center "><img src="assets/accout-setting-icon.png"/>Account Settings</a></li>
              <li className="liMenu"><a href="/" className="lms-flex-align-center "><img src="assets/help-icon.png"/>Help</a></li> */}
              {/* <li className="liMenu"><HandleLogout /></li> */}
            </ul>
            <div className="terms-conditions-con">
              <NavLink to={"/" + "/terms&conditions"} target="blank"><span>Terms & Conditions</span></NavLink>
              <NavLink to={"/" + "/privacy&policy"} target="blank"><span>Privacy Policy</span></NavLink>
            </div>
          </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state)
  return {
    profile: state.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signOutAction: signOutUser,
      getProfileData: getProfileData,

    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropDown);
