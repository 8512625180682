import React, { useEffect } from 'react';
import UserSearchFilter from "./UserData/SearchFilter";
import UserDatagriddata from "./UserData/Datagriddata"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllUsers } from '../../store/actions/AllUsersAction';
import { fetchAdminCourse, fetchAdminVideo, getAllRoles } from './store/actions/admin';
import VideoDatagriddata from './Video/VideoDatagriddata';

const CollectionData = (props) => {
    const [filterUser, setFilterUser] = React.useState({
        Count: 0,
        Data: [],
    });
    const [selections, setSelections] = React.useState([]);
    const [userSelection, setUserSelection] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    const handleUserSelection = (event) => {
        setUserSelection(event);
    };

    const handleFilterUser = (event) => {
        console.log('sort', event);
        setFilterUser({
            Count: event.Count,
            Data: event.Data,
        });
    };

    const handleModalAction = (event) => {
        setIsOpen(event);
    };

    const handleSearchValue = (event) => {
        setSearchValue(event);
    };

    const handleSelection = (event) => {
        setSelections(event);
    };

    useEffect(() => {
        const { fetchAllUserAction, fetchRolesAction, fetchAdminVideoAction, fetchAdminCourseAction, videoCollectionPageNumber, courseCollectionPageNumber } = props;
        fetchAllUserAction({
            PageNumber: 1,
            PageSize: 20
        })
        fetchRolesAction({
            PageNumber: 1,
            PageSize: 0
        })
        videoCollectionPageNumber === 2 &&
            fetchAdminVideoAction({
                pageNumber: 1
            });
        courseCollectionPageNumber === 2 &&
            fetchAdminCourseAction(1);
        // window.addEventListener("resize", this.winowWidthUpdate);
    }, [])

    return (
        <section id="colle-grid" className='analytics-admin-space'>
            <VideoDatagriddata selection={handleSelection} />
        </section>
        // <UserSearchFilter
        //     selection={userSelection}
        //     filterData={handleFilterUser}
        //     modal={handleModalAction}
        //     searchValue={handleSearchValue}
        // />
        // <UserDatagriddata
        //     selection={handleUserSelection}
        //     filterData={filterUser}
        //     modal={isOpen}
        //     searchValue={searchValue}
        // />
    );
};

const mapStateToProps = (state) => ({
    role: state.profileData.Role,
    videoCollectionPageNumber: state.tutorials.collectionPageNumber,
    courseCollectionPageNumber: state.courses.collectionPageNumber,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAllUserAction: getAllUsers,
        fetchRolesAction: getAllRoles,
        fetchAdminVideoAction: fetchAdminVideo,
        fetchAdminCourseAction: fetchAdminCourse,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(CollectionData);
