import actionTypes from "./actionTypes";

const fetchCategories = () => {
  return {
    type: actionTypes.FETCH_CATEGORY_START,
  };
};

const fetchPlaylistCategories = () => {
  return {
    type: actionTypes.FETCH_PLAYLIST_CATEGORY_START,
  };
};
export { fetchCategories, fetchPlaylistCategories }