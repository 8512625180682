import { liveCourseProviders } from "../Common/Constants";

const getSessionPayloadBody = (provider, duration, sessionName, endTimeInHour) => {
  switch (provider) {
    case liveCourseProviders.VideoSdk: {
      var payloadBody = {
        endTimeInHour: endTimeInHour,
        customRoomId: `${sessionName}-${crypto.randomUUID()}`,
        autoCloseConfig: {
          type: "session-end-and-deactivate",
          duration: duration
        },
        autoStartConfig: {
          autoStartConfig: {
            recording: {
              transcription: {
                enabled: true,
                summary: {
                  enabled: true,
                  prompt:
                    "Write summary in sections like Title, Agenda, Speakers, Action Items, Outlines, Notes and Summary"
                }
              },
              config: {
                layout: {
                  type: "GRID",
                  priority: "SPEAKER",
                  gridSize: 4
                }
              }
            },
            hls: {
              transcription: {
                enabled: true,
                summary: {
                  enabled: true,
                  prompt:
                    "Write summary in sections like Title, Agenda, Speakers, Action Items, Outlines, Notes and Summary"
                }
              },
              config: {
                layout: {
                  type: "GRID",
                  priority: "SPEAKER",
                  gridSize: 4
                },
                recording: {
                  enabled: "true"
                }
              }
            }
          }
        }
      };

      return payloadBody
    }
    default:
      return {};
  }
};

export default getSessionPayloadBody
