
import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import Cookies from "js-cookie";
import ErrorTab from "../Common/ErrorTab";
import { CourseType } from "../../modules/Admin/Common/Constants";
import VideoUploadedDate from "../Common/VideoUploadedDate";

export const CoursesList = (props) => {
  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((course, idx) => {
          return (
            <li className="card-space" key={course.Id}>
              <Link to={"/" + "/course/" + course.Id + "/0"}>
                <div className="card-img">
                  <img
                    src={course.ThumbnailUrl}
                    alt="lms-logo" />
                </div>
                <div className="card-info lms-flex-between">
                <div>
                    <p>{course.CategoryName}</p>
                  </div>
                  <div className="card-date">
                    <VideoUploadedDate createdDate = {course.CreatedDate} />
                  </div>
                </div>
                <div className="card-heading">{course.Name}</div>
                <div className="card-para">{course.Description}</div>
              </Link>
              <div className="course-webinar-differ">{course.CourseType === CourseType.Offline ? "Course" : "Live Course"}</div>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else if (props.loader && props.props.length === 0) {
    return (
      <div className="gif">
        <div className="spinner-border">
          <img src="../assets/loading.gif" alt=""></img>
        </div>
      </div>
    );
  }
  else {
    return <ErrorTab class={"no-result"} />
  }
};

export default CoursesList;
