import React, { useEffect, useState } from 'react'

import { Outlet, Navigate, useLocation, useParams, useNavigate } from "react-router-dom";
import Header from "../components/Common/Header";
import SideNav from "../components/Common/SideNav";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import 'bootstrap/dist/css/bootstrap.css';
import { connect } from "react-redux";
import { loginRequest } from '../utils/authConfig';
import Cookies from 'js-cookie';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { blueBarAction } from '../store/actions/BlueBarAction';
import { RequireAuth, useFusionAuth } from '@fusionauth/react-sdk';
import TenantMapping from './Admin/Common/TenantMapping';
import { fetchTenantDetails, fetchTenantDetailsAction } from './Admin/store/actions/admin';
import API from './FusionAuth/Utils/API';
import { getUserDetails } from '../store/actions/userActions';
import moment from 'moment';
import Logout from '../components/AuthComponents/logout';
import { Alert, Backdrop } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { jwtExprieTime } from '../components/Common/Constants';
import { heIL } from '@mui/x-data-grid';
import { height } from '@mui/system';
function Layout(props) {
  const location = useLocation();
  //Adding Protecte Route
  const curLoc = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const { isAuthenticated, login, isLoading, user } = useFusionAuth();
  
  const { t } = useTranslation()
  const Session_Out = t("Session_Out")

  useEffect(() => {
    var expTime = Cookies.get(jwtExprieTime)
    if (isNaN(expTime)) {
      setOpen(true)
    }
    if (window.location.pathname !== '/' && window.location.pathname !== '/videos' && window.location.pathname != '/courses') {
      props.handleBlueBar(true)
    }
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
        window.location.reload()
      }, 5000);
    }
  }, [open]);

  console.log("line 56", user)
  useEffect(() => {
    props.getUserData(user)
  }, [user]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      var tenantName = sessionStorage.getItem('tenant')
      navigate("/login?redirectUri=/")
    }
  }, [isAuthenticated])

  return !isLoading && (
    <RequireAuth>
      <div class="container-fluid">
        <div class="row">
          <div id="side-nav-main" class="p-0 col-sm-1"><SideNav /></div>
          <div class={`col-lg-11 col-sm-12 ${window.document.body.dir === 'rtl' ? "right-pad": "left-pad"}`}>
            <div
              className="page-container"
            >
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <Alert severity="warning" className='session-warning-alert'>
                  {Session_Out}
                </Alert>
              </Backdrop>
              <div className="lms-res-headerspace">
                <Header props={props} />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </RequireAuth>

  );
}
const mapStateToProps = (state) => ({
  user: state.profileData,
  tenant: state.tenant,
  userClaims: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleBlueBar: blueBarAction,
      fetchTenant: fetchTenantDetailsAction,
      getUserData: getUserDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);