import React from 'react'
import Datagriddata from './Datagriddata';
import SearchFilter from './SearchFilter';
import API from '../utils/API';

export default function VideoDatagriddata(props) {
    const [selections, setSelections] = React.useState([]);
    const [selectedVideos, setSelectedVideos] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCourseToAdd, setSearchCourseToAdd] = React.useState([]);
    const [searchCourseToAddValue, setSearchCourseToAddValue] = React.useState("");
    const [searchCourseToAddLocalLoader, setSearchCourseToAddLocalLoader] = React.useState(false);

    const [filterData, setFilterData] = React.useState({
        Count: 0,
        Data: [],
        StatusCode: 200,
    });

    React.useEffect(() => {
        setSelectedVideos([])
        setSelections([]);
        setFilterData({
            Count: 0,
            Data: [],
            StatusCode: 200,
        })
    }, [window.location.pathname.split('/').pop()])


    const handleSelection = (event) => {
        setSelections(event);
    };

    // const handleIndex = (event) => {
    //     props.selection(selections)
    //     props.getIndex(event)
    // };

    const handleFilterCollection = (event) => {
        setFilterData({
            Count: event.Count,
            Data: event.Data,
            StatusCode: event.StatusCode
        });
    };

    const handleSelectedVideos = (event) => {
        console.log(event)
        setSelectedVideos(event);
    };
    const hanldeSearchValue = (event) => {
        setSearchValue(event)
    };


    const handleSearchCourseToAdd = (event) => {
        setSearchCourseToAddLocalLoader(true)
        setSearchCourseToAddValue(event)
        const timer = setTimeout(() => {
            API.fetchAdminCourseFilter({
                filterField: "Title",
                filterText: event.trim()
            }).then(res => res.status === 200 && setSearchCourseToAdd(res.data.Playlist)).finally(res => setSearchCourseToAddLocalLoader(false))
            setSearchValue(event)
        }, 500);
        return () => clearTimeout(timer)
    };
    return (
        <>
            <SearchFilter searchCourseToAddValue={searchCourseToAddValue} searchCourseToAddData={searchCourseToAdd} searchCourseToAddLocalLoader={searchCourseToAddLocalLoader} selection={selections} filterData={handleFilterCollection} searchValue={hanldeSearchValue} searchCourseToAdd={handleSearchCourseToAdd} />
            <Datagriddata
                selection={handleSelection}
                selectedVideos={handleSelectedVideos}
                filterData={filterData} searchValue={searchValue} />
        </>
    )

}

