import React from "react";
import UploadVideo from "./UploadVideo";
import VerticalTimeline from "./VerticalTimeline";
import { Alert, Backdrop } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import HorizontalStepper from '../../../components/Common/HorizontalStepper';
import BreadcrumbsNav from "../Common/BreadcrumbsNav";

function VideoFirst(props) {
  const [getFiles, setGetFiles] = React.useState([])
  const [hasFiles, setHasFiles] = React.useState(false)
  const [getThumbnail, setGetThumbnail] = React.useState('')
  const [videolength, setVideoLength] = React.useState(0)
  const [isUploadDone, setIsUploadDone] = React.useState(false)
  const [videoUploaded, setVideoUploaded] = React.useState({})
  const [thumbnailUploaded, setThumbnailUploaded] = React.useState({})
  const [documnetUploaded, setDocumentUploaded] = React.useState({})
  const [backdropOpenVideo, setbackDropOpenVideo] = React.useState(false);
  const [backdropOpenThumbanil, setbackDropOpenThumbnail] = React.useState(false);
  const [backdropOpenDocument, setbackDropOpenDocument] = React.useState(false);
  const [steps] = React.useState(['Upload Videos', 'Add Details']);
  const [activeStep, setActiveStep] = React.useState(1);
  const { t } = useTranslation();

  const Video_Upload_Progress = t("Video_Upload_Progress")

  const handleBackDropClose = () => {
    setbackDropOpenVideo(false);
    setbackDropOpenThumbnail(false);
    setbackDropOpenDocument(false);
  };

  const handleIsVideoUploadDone = (e) => {
    setIsUploadDone(e)
    setbackDropOpenVideo(e);
  };

  const handleVideoUpload = (e) => {
    setVideoUploaded(e)
    sessionStorage.removeItem('percentage')
  };

  const handleThumbanilUpload = (e) => {
    setThumbnailUploaded(e)
  };

  const handleDocumentUpload = (e) => {
    localStorage.removeItem('docs')
    setDocumentUploaded(e)
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={alert.active}
        onClick={handleBackDropClose}
      >
        <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
          {alert.message}
        </Alert>
      </Backdrop>
      <div className="video-tab-con upload-con-width">
      <BreadcrumbsNav
            activeTtile="Add individual Video"
            setStudioLandingPage={props.setStudioLandingPage}
          />
      </div>
      <div className="upload-center-con">
        <div className="upload-con-width">
          <HorizontalStepper steps={steps} activeStep={activeStep} />
          <div className="d-flex gap-5">
            <div className={`${hasFiles ? "w-50 upload-white-bg" : ""}`}>
              <UploadVideo getFiles={(e) => { setGetFiles(e) }} hasFiles={setHasFiles} getThumbnail={(e) => { setGetThumbnail(e) }} isUploadDone={isUploadDone} videolength={(e) => { setVideoLength(e) }} />
            </div>
            <div className={`${hasFiles ? "w-50 upload-white-bg" : ""}`}>
              <VerticalTimeline
                files={getFiles}
                thumbnail={getThumbnail}
                isUploadDone={(e) => handleIsVideoUploadDone(e)}
                videoUploaded={(e) => handleVideoUpload(e)}
                thumbnailUploaded={(e) => handleThumbanilUpload(e)}
                documentUploaded={(e) => handleDocumentUpload(e)}
                videolength={videolength} />
            </div>
          </div>
        </div>
      </div></>
  );
}



export default VideoFirst;



